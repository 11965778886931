.App {
  text-align: center;
}
@import url("https://fonts.googleapis.com/css2?family=Zilla+Slab:wght@500&display=swap");
/* body {
  font-family: "Zilla Slab", serif !important;
} */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
h2 {
  font-size: 42px !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.page-width {
  width: 80rem;
  margin: 0 auto;
  font-size: 1.3rem;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.theme-text {
  font-size: 18px !important;
  color: #002b54;
}
.theme-color {
  background-color: #b8014c !important;
}
.form-control:focus {
  border-color: #b8014c !important;
  box-shadow: 0 0 0 0.2rem rgba(184, 1, 76, 0.65) !important;
}
.form-control {
  font-size: 16px !important;
  color: #002b54 !important;
}

Label {
  font-size: 18px;
  font-weight: 700;
  font-family: "Public Sans", sans-serif;
}
.themebox:focus {
  border-color: #b8014c !important;
  box-shadow: 0 0 0 0.2rem rgba(184, 1, 76, 0.65) !important;
}

.logout {
  border-color: #b8014c !important;
  color: #b8014c !important;
  background-color: #ffffff !important;
}

::marker {
  content: counters(list-item, ".") "";
}
li {
  padding-left: 0.5em;
}
.Links {
  text-decoration: none;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.JustifyTerms {
  text-align: justify;
  text-justify: inter-word;
}

.css-1s2u09g-control {
  max-height: 38px !important;
}

.css-qc6sy-singleValue {
  font-size: 16px !important;
  color: #002b54 !important;
}

.selectBox__control--is-focused {
  border-color: #b8014c !important;
  box-shadow: 0 0 0 0.2rem rgba(184, 1, 76, 0.65) !important;
}

.nested-list-1 {
  margin-top: 15px !important;
}
.nested-list-0 {
  margin-top: 25px !important;
}

.alphabet-list-order {
  width: 3% !important;
  margin-bottom: auto !important;
}

.alphabet-list-value {
  width: 97% !important;
}
